html {
    font-size: 62.5%;
}

body {
    font-family: Helvetica, Arial, sans-serif;
    font-size: $m-size;
}

p {
    font-weight: bold;
    font-size: $m-size;
    padding: 0 $xl-size;
}

h2 {
    color: darken($color: yellow, $amount: 5%);
}
button {
    background-color: darken($color: $blue-900, $amount: 15%);
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    font-size: $m-size;
}

.button--submit{
    min-width: 200px;
    min-height: $xl-size;
}
.button--copy {
    margin-left: $s-size;
}

.button--helpModal {
    width: $xl-size;
    height: $xl-size;
    position: absolute;
    right: 0px;
    bottom: 0px;

}
button:disabled {
    cursor: default;
    background-color: gray;

}

