label {
    display: flex;
    font-size: x-large;
    font-weight: bold;
    padding: 0 5px;
  }

  .label--message {
    height: auto;
    position: absolute;
    bottom: 0px;
}

.label--message-container {
    display: flex;
    position: relative;
}


  .textForm {
    margin-top: $xl-size;
    max-width: 400px;
}

.inputForm--url {
    margin-bottom: $xl-size;
}

.inputForm--message {
    margin-bottom: $s-size;
}

.shortUrlForm {
    margin-top: $s-size;
    display: flex;    
}

textarea, input{
    border: 2px solid $gray-200;
    border-radius: 10px;
    background-color: $gray-100;
    
    color: $gray-800;
    font-size: $m-size;
    padding: $s-size 5px;
    width: 96.24%;
}

.input--shortUrl{
    max-width: 210px;
}

textarea {
    height: 200px;
}

input:focus,
textarea:focus {
  outline: none;
}

.error {
    color: red;
    padding-left: 5px;
    position: absolute;
    font-size: 16px;
}

.warning {
    background-color: red;
    font-weight: bold;
    border-radius: 10px;
    padding: $s-size;
    margin-top: 0px;
    text-align: center;
}