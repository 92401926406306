.header {
    background: $off-black;
    margin: 0;
    padding: $s-size 0;
}

.header__content {
    display: flex;
}

.header__title {
    color: white;
    font-size: $xl-size;
    font-weight: bold;
    margin: 0;
    text-decoration: none;
}

.header__subtitle {
    color: $off-white;
    font-size: $m-size;
    font-weight: 900;
    line-height: 1.3em;
    margin-bottom: 0;
    margin-left: $m-size;
    min-width: 20rem;
}

