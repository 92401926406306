.ReactModalPortal > div {
    opacity: 0;
}
.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

.helpModal {
    border-radius: $l-size;
    background: darken($color: $blue-900, $amount: 15%);
    color: white;
    max-width: 40rem;
    outline: none;
    padding: $l-size;
    padding-top: 0px;
    text-align: center;
}