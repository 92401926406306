
@media (max-width: 420px) {
    .header{
        width: auto;
        display: block;
    }

    .header__title {
        padding-left: $s-size;
    }
    .header__subtitle {
        min-width: $s-size;
        font-size: 1.3rem;
        padding-top: 0.5rem;
    }

    .button--submit{
        width: 100%;
    }
}